import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import MHP from "../../assets/MHP.jpg";
import brajuu from "../../assets/brajuu.jpeg";
import orderspot from "../../assets/orderspot.jpeg";
import { motion, useInView } from "framer-motion";
const ExperienceWrapper = styled.div`
    background: #fff;
    position: relative;
    z-index: 100;
`;
const GridSys = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 1rem;
    width: 85%;
    background: #fff;
    margin: 4rem auto 0;
    @media (min-width: 680px) {
        grid-template-columns: repeat(3, 34%);
        grid-template-rows: 100%;
        grid-column-gap: 1rem;
        width: 85%;
    }
`;
const Title = styled.h1`
    text-align: left;
    color: #3a4f41;
    width: 85%;
    margin: 0rem auto;
    padding-top: 4rem;
    font-weight: 500;
`;
const Img = styled.img`
    width: 100%;
    border: 1px solid #00000017;
    border-radius: 50px;
`;
const GridItem = styled.div`
    display: grid;
    grid-template-rows: max-content max-content max-content max-content;
`;
const Workplace = styled.h2`
    margin-bottom: 0.375rem;
    font-size: 1.25rem;
`;
const Position = styled.p`
    color: #18a0fb;
    margin-top: 0;
    font-weight: 600;
    font-size: 1rem;
    margin-bottom: 0.357rem;
`;
const ImgWrap = styled.div`
    width: 90px;
    height: 90px;
    margin: 0;
`;

const JobDescription = styled.p`
    display: block;
    color: #0f0f0f;
    align-self: start;
    margin-bottom: 2rem;
    > a {
        text-decoration: none;
        color: #0f0f0f;
        font-weight: 600;
    }
`;
const Timeline = styled.p`
    margin-top: 0;
    font-size: 0.85rem;
`;
export const Experience = ({}) => {
    const ref = useRef(null);
    const isInview = useInView(ref, { once: true });

    {
        return (
            <ExperienceWrapper id="experience">
                <Title>Professional Working Experience</Title>
                <GridSys>
                    <GridItem>
                        <motion.div
                            initial={{ opacity: 0, y: 75 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.55, delay: 0.75 }}
                        >
                            <ImgWrap>
                                <Img src={MHP}></Img>
                            </ImgWrap>
                            <Workplace>MHP – A Porsche Company </Workplace>
                            <Position>
                                Working Student – Software Engineer
                            </Position>
                            <Timeline>08/23 – 01/24</Timeline>
                            <JobDescription>
                                MHP is a leading international management and IT
                                consultancy. As a working student, I was able to
                                expand my skills and knowledge in MHP's
                                <a href="https://www.mhp.com/de/services/customer-experience">
                                    Customer Experience
                                </a>
                                Cluster, helping to develop impressive car
                                configurators for various premium car
                                manufacturers.
                            </JobDescription>
                        </motion.div>
                    </GridItem>

                    <GridItem>
                        <motion.div
                            initial={{ opacity: 0, y: 75 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.55, delay: 1.25 }}
                        >
                            <ImgWrap>
                                <Img src={brajuu}></Img>
                            </ImgWrap>
                            <Workplace>Brajuu GmbH </Workplace>
                            <Position>
                                Working Student – Frontend Development
                            </Position>
                            <Timeline>01/22 – 05/23</Timeline>
                            <JobDescription>
                                Working Part-time as a Frontend Developer at
                                Brajuu Gmbh helped me to gain a lot of
                                experience, especially in the field of
                                developing web applications. Together with the
                                CTO of the company, we build the
                                <a href="https://boobtech.noahwalz.com">
                                    web app you can see at the top of my
                                    portfolio
                                </a>
                                . Furthermore I transformed the web application
                                into a widget, making it easy for third-parties
                                to include it in their website.
                            </JobDescription>
                        </motion.div>
                    </GridItem>

                    <GridItem>
                        <motion.div
                            initial={{ opacity: 0, y: 75 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.55, delay: 1.75 }}
                        >
                            <ImgWrap>
                                <Img src={orderspot}></Img>
                            </ImgWrap>
                            <Workplace>Orderspot GmbH </Workplace>
                            <Position>
                                Internship – Frontend Development
                            </Position>
                            <Timeline>10/21 – 12/21</Timeline>
                            <JobDescription>
                                Working as an Internship at Orderspot as my
                                first Job in the field of Frontend Developement
                                was a real pleasure! In only 3 months I learned
                                a lot about the Framework angular, which was
                                used as the frontend part of the self developed
                                SASS-Platform and also about using and
                                communicating with APIs, but also many more
                                things that are necessary to develop fantastic
                                frontends.
                            </JobDescription>
                        </motion.div>
                    </GridItem>
                </GridSys>
            </ExperienceWrapper>
        );
    }
};

export default Experience;
