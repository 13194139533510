import React from "react";
import styled from "styled-components";
import linkedIn from "../../assets/linkedin.png";
import mail from "../../assets/mail.png";
import instagram from "../../assets/instagram.png";

const FooterWraper = styled.div`
    background: #fff;
    position: relative;
    bottom: 0;
    padding-top: 4rem;
    height: 15vh;

    width: 100%;
    z-index: 100;
`;
const FooterGrid = styled.div`
    display: grid;

    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: 100%;
    margin: 0 auto;
    grid-gap: 1rem;
    width: 100%;
    @media (min-width: 480px) {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: 100%;
    }
`;
const Footeritem = styled.div`
    justify-self: center;
    align-self: center;
    font-weight: 700;
    width: 85%;
    text-align: center;
`;
const ClosingText = styled.p`
    text-align: center;
    font-size: 0.75rem;
    margin: 0 auto;
    padding: 1rem 0;
    position: relative;
`;
class Footer extends React.Component<{}> {
    render() {
        return (
            <>
                <FooterWraper>
                    <FooterGrid>
                        <Footeritem>Home</Footeritem>
                        <Footeritem>Portfolio</Footeritem>
                        <Footeritem>Tech-Stack</Footeritem>
                        <Footeritem>Work Experience</Footeritem>
                        <Footeritem>Contact</Footeritem>
                    </FooterGrid>
                    <ClosingText> Made with ❤️ by Noah Walz </ClosingText>
                </FooterWraper>
            </>
        );
    }
}

export default Footer;
