import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Menü from "./nav";
import ProgressBar from "./progressbar";
// Create a Title component that'll render an <h1> tag with some styles

// Create a Wrapper component that'll render a <section> tag with some styles
const Wrapper = styled.header<{ height: number }>`
    padding: 2rem 0;
    background: #3a4f41;
    position: relative;
    top: 0;
    zindex: 999999999;
    width: 100%;
    color: #ecd3a3;
  
    @media (min-width: 460px) { 
        padding: 4rem 0;
`;

const HeaderWrapper = styled.div`
    margin-left: 15vw;
`;

const Brand = styled.a`
    color: #000;
    text-decoration: none;
    font-weight: 600;
    font-size: 1rem;
    float: left;
    display: block;
    text-shadow: 0px 0px 2px black;
    :hover {
        text-decoration: overline solid rgb(238, 238, 238);
        -webkit-transition: 0.3s;
        transition: 0.3s;
        color: rgb(255, 255, 255);
        background-color: transparent;
    }
`;
const InitialWrap = styled(Menü)<{}>`
    position: relative !important;
`;
class NavBar extends React.Component<{ height: number | any }> {
    progress!: number;
    height!: number;

    useState() {
        const scrollHandler = () => {
            const winScroll =
                document.body.scrollTop || document.documentElement.scrollTop;
            const docHeight =
                document.documentElement.scrollHeight -
                document.documentElement.clientHeight;
            const scrolled = (winScroll / docHeight) * 100;

            const headerElement = document.getElementById("head");
            const menuElement = document.getElementById("menu");

            if (headerElement && menuElement) {
                headerElement.style.background =
                    scrolled === 0 ? "#3a4f41" : "transparent";
                headerElement.style.width = scrolled === 0 ? "100%" : "auto";
                headerElement.style.transition =
                    scrolled === 0 ? "0.51s" : "none";
                menuElement.style.background =
                    scrolled === 0 ? "black" : "transparent";
            }
            this.height = this.props.height;
        };
        window.addEventListener("scroll", scrollHandler);
        return () => {
            window.removeEventListener("scroll", scrollHandler);
        };
    }

    render() {
        this.useState();
        return (
            <>
                <Wrapper
                    height={this.height}
                    className="head-stick sticky"
                    id=""
                >
                    <InitialWrap></InitialWrap>
                    <HeaderWrapper className="header-brand-wrapper">
                        <Brand
                            id="noah"
                            className="header-brand"
                            href="index.html"
                        ></Brand>
                    </HeaderWrapper>
                </Wrapper>

                <ProgressBar progress={this.progress} />
            </>
        );
    }
}

export default NavBar;
function setScrollPosition(scrollPercentage: number) {
    throw new Error("Function not implemented.");
}
