import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import react from "../../assets/react.png";
import sass from "../../assets/sass.png";
import api from "../../assets/api.png";
import html from "../../assets/html.png";
import tailwind from "../../assets/tailwindcss.png";
import git from "../../assets/git.png";
import angular from "../../assets/angular.png";
import javascript from "../../assets/javaScript.png";
import jira from "../../assets/jira.png";
import adobe from "../../assets/adobe.png";
import figma from "../../assets/figma.png";
import css from "../../assets/css.png";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, A11y, Autoplay } from "swiper/modules";
const Wrapper = styled.div`
    position: relative;
    display: block;
    width: 100%;
    background: #e6e6e6;
    z-inedx: 99;
    padding: 2rem 0;
`;
const WrapperTitle = styled.div``;
export const Title = styled.h1`
    text-align: center;
    color: #3a4f41;
    font-weight: 500;
`;
const SubTitle = styled.h3`
    text-align: center;
    color: #69393c;
    font-weight: 300;
`;

const StyledSwiperSlide = styled(SwiperSlide)`
    margin: auto;
`;
const StyledSwiper = styled(Swiper)`
    margin: 1rem;
    @media (min-width: 640px) {
        margin: 1rem 5rem;
    }
`;
const Img = styled.img`
    width: 75%;
    padding: 2rem 0;
`;

export const Skill = ({}) => {
    const ref = useRef(null);
    const isInview = useInView(ref, { once: true });
    {
        return (
            <>
                <Wrapper id="skills">
                    <WrapperTitle>
                        <motion.div
                            initial={{ opacity: 0, y: 75 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.25, delay: 0.35 }}
                            viewport={{ once: true }}
                        >
                            <Title>Skills</Title>
                            <SubTitle>
                                <strong>
                                    Programming Languages, Stylesheets and
                                    Applications used in projects and Jobs
                                </strong>
                            </SubTitle>
                        </motion.div>
                    </WrapperTitle>
                    <motion.div
                        initial={{ opacity: 0, y: 100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.25, delay: 0.35 }}
                        viewport={{ once: true }}
                    >
                        <StyledSwiper
                            breakpoints={{
                                320: {
                                    slidesPerView: 2
                                },
                                // when window width is >= 480px
                                480: {
                                    slidesPerView: 3
                                },
                                // when window width is >= 780px
                                780: {
                                    slidesPerView: 4
                                }
                            }}
                            modules={[Scrollbar, A11y, Autoplay]}
                            spaceBetween={50}
                            slidesPerView={4}
                            autoplay={{
                                delay: 1500,
                                pauseOnMouseEnter: true
                            }}
                            loop={true}
                            watchSlidesProgress={true}
                            scrollbar={{ draggable: true }}
                        >
                            <StyledSwiperSlide>
                                <Img src={html} alt="HTML" />
                            </StyledSwiperSlide>
                            <StyledSwiperSlide>
                                <Img src={css} alt="CSS" />
                            </StyledSwiperSlide>
                            <StyledSwiperSlide>
                                <Img src={sass} alt="SASS" />
                            </StyledSwiperSlide>
                            <StyledSwiperSlide>
                                <Img src={react} alt="React" />
                            </StyledSwiperSlide>
                            <StyledSwiperSlide>
                                <Img src={figma} alt="Figma" />
                            </StyledSwiperSlide>
                            <StyledSwiperSlide>
                                <Img src={tailwind} alt="TailwindCSS" />
                            </StyledSwiperSlide>
                            <StyledSwiperSlide>
                                <Img src={api} alt="API" />
                            </StyledSwiperSlide>
                            <StyledSwiperSlide>
                                <Img src={adobe} alt="Adobe Photoshop" />
                            </StyledSwiperSlide>
                            <StyledSwiperSlide>
                                <Img src={angular} alt="Angular" />
                            </StyledSwiperSlide>
                            <StyledSwiperSlide>
                                <Img src={git} alt="GIT" />
                            </StyledSwiperSlide>
                            <StyledSwiperSlide>
                                <Img src={jira} alt="Jira" />
                            </StyledSwiperSlide>
                            <StyledSwiperSlide>
                                <Img src={javascript} alt="JavaScript" />
                            </StyledSwiperSlide>
                        </StyledSwiper>
                    </motion.div>
                </Wrapper>
            </>
        );
    }
};

export default Skill;
