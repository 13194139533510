import React from "react";
import styled from "styled-components";

const ProgressWrapper = styled.div``;

const Progress = styled.span<{
    progress: Number;
}>`
    background: rgb(14, 199, 255);
    width: ${({ progress }) => (progress ? "100%" : "10%")};
    z-index: 96;
    position: fixed;
    height: 7px;
    display: none;
`;

class ProgressBar extends React.Component<{ progress: number }> {
    render() {
        return (
            <ProgressWrapper>
                <Progress progress={this.props.progress} />
            </ProgressWrapper>
        );
    }
}

export default ProgressBar;
