import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`  
@font-face {
´
  body {
    font-family: "Noto Sans Adlam";
  }`;

export default GlobalStyle;