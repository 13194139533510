import React from "react";
import styled from "styled-components";

const Label = styled.label`
    display: flex;
    align-items: center;
    width: 93%;
    justify-content: center;
    height: 0px;
    margin-top: 1rem;
    position: relative;
    z-index: 999999;
    @media (min-width: 480px) {
        width: 100%;
        margin-top: 0rem;
    }
`;
const Hamburger = styled.span` 
    left: 75%;
    content: '';
    background-color: #69393c;
    position: fixed;
    height: 3px;
    display: block;
    width: 3rem;
    margin-top: -7vh;
    z-index: 10000000000000;
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out;
    @media (min-width: 480px){
    margin-top: 1%;
    width: 3rem;
    left: 83.7%;
    margin-top: -7vh;
    }
    @media (min-width: 848px){
        left: 88.7%;
    }
:after {
    width: 3rem;
    content: '';
    background-color: #69393c;
    margin-left: 50%;
    position: absolute;
    height: 3px;
    margin-top: 7px;
    z-index: 1000000000000000;
 }
 :before{
    width: 3rem;
    content: '';
    background-color: #69393c;
    margin-left: 50%;
    position: absolute;
    height: 3px;
    margin-top: -7px;
    z-index: 1000000000000000;
    @media (min-width: 480px){
        margin-left: 50%;
  }
`;
const NavWrapper = styled.div`
    background: rgba(86, 86, 103, 0.9);
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    transform: scaleX(0);
    transform-origin: right;
    transition: transform 0.4s ease-out;
    z-index: 200;
    @media (min-width: 480px) {
        width: 100%;
        text-align: center;
    }
`;

const Input = styled.input` 
  display: block ;
  position: fixed;
  width: 6%;
  opacity: 0;
  right: 8%;
  z-index: 9999;
  right: 8%;
  height: 4rem;
  margin-bottom: 1rem;
  top: 6vh;
  :checked ~ ${Label} ${Hamburger} {
    transform: rotate(135deg) translate(0px);
    transition: transform .5s ease-out;
    background-color: #fff;
 
  }
  :checked ~ ${Label} ${Hamburger}:before  {
    width: 100%;
    background-color: #fff;
    transform: rotate(-90deg) translate(-0.4rem, -1.5rem);
    transition: transform .4s ease-out;
  @media (min-width: 480px) {
    transform: rotate(-90deg) translate(-0.4rem, -1.5rem)
    }
  }
  :checked ~ ${Label} ${Hamburger}:after  {
    opacity: 0;
    transition: opacity .02s ease-out;
    }
    
    @keyframes opacity {
      from {
          
          opacity: 1;
        }
        to {
           opacity: 0;
        }
    }
}
  :checked ~ ${NavWrapper} {
    width: 100%;
    background: #56566791;
    box-shadow: -3px 0 8px #fff;
    transform: scale(1);
    z-index: 200;
   

    @media (min-width: 1000px){
      width: 100%;
      text-align: center;
    }
  }
   a:visited ~ ${NavWrapper} {
    width: 0%;
  }
  @media (min-width: 480px){
      width: 6%;
      right: 8%;
      height: 4rem;
      margin-bottom: 1rem;
      top: 6vh;
  }
 
  @media (min-width: 680px){
    right: 6%;
    top: 1vh;
  }`;
const PseudoText = styled.span`
    opacity: 0;
`;

const Ul = styled.ul`
    top: 150px;
    margin-left: 16%;
    position: absolute;
`;

const Li = styled.li`
    list-style: none;
    text-transform: uppercase;
    color: white;
    width: 65vw;
    margin-left: 16%;
    position: absolute;
    font-size: 1.25rem;
    @media (min-width: 680px) {
        font-size: 2rem;
    }
    :nth-child(1) {
        margin-top: 00vh;
    }
    :nth-child(2) {
        margin-top: 15vh;
    }
    :nth-child(3) {
        margin-top: 30vh;
    }
    :nth-child(4) {
        margin-top: 45vh;
    }
    :nth-child(5) {
        margin-top: 60vh;
    }

    :focus ~ ${NavWrapper} {
        width: 0%;
        background: #56566791;
        box-shadow: -3px 0 8px #fff;
        transform: scale(1);
        z-index: 20;
        @media (min-width: 480px) {
            width: 40%;
        }
        @media (min-width: 1000px) {
            width: 100%;
            text-align: center;
        }
    }
    :hover::after {
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
    }
    a {
        color: white;
        text-decoration: none;
        position: relative;
        display: inline-block;
        color: white;
        overflow: hidden;
        background: linear-gradient(to right, white, white 50%, black 50%);
        background-clip: text;
        font-weight: 700;
        letter-spacing: 0.7rem;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-size: 200% 100%;
        background-position: 100%;
        transition: background-position 275ms ease;
        text-decoration: none; // text decorations are clipped in WebKit browsers
        &:hover {
            background-position: 0 100%;
        }
        :visited ~ ${NavWrapper} {
            width: 0%;
        }
    }
`;
const Wrapper = styled.div``;
const Nav = styled.nav``;
class Menü extends React.Component {
    render() {
        return (
            <Wrapper>
                <Nav>
                    <Input id="nav-toggle" type="checkbox" />
                    <PseudoText>Text</PseudoText>

                    <Label htmlFor="nav-toggle">
                        <Hamburger id="menü"></Hamburger> {/* span */}
                        <PseudoText>Text</PseudoText>
                    </Label>
                    <NavWrapper className="nav-wrapper" id="navbar-wrapper">
                        <Ul>
                            <Li>
                                <a href="#portfolio">Portfolio</a>
                            </Li>
                            <Li>
                                <a href="http://boobtech.noahwalz.com">
                                    Brajuu Web Application
                                </a>
                            </Li>
                            <Li>
                                <a href="#skills">Tech-Stack</a>
                            </Li>
                            <Li>
                                <a href="#experience">
                                    Professional Experience
                                </a>
                            </Li>
                            <Li>
                                <a href="#contact">Contact</a>
                            </Li>
                        </Ul>
                    </NavWrapper>
                </Nav>
            </Wrapper>
        );
    }
}

export default Menü;
