import React from "react";
import styled from "styled-components";
import linkedIn from "../../assets/linkedin.png";
import mail from "../../assets/mail.png";
import instagram from "../../assets/instagram.png";
import { motion } from "framer-motion";
const Wrapper = styled.div`
    background: #e6e6e6;
    position: relative;
    display: block;
    margin: 0;
    z-index: 100;
`;

const ContactButton = styled.button`
    grid-column: 4;
    width: 200px;
    height: 50px;
    background: #fff;
    color: #18a0fb;
    border: 1px solid #18a0fb;
    border-radius: 5px;
    font-size: 1rem;
    font-weight: 600;
    @media (min-width: 480px) {
        grid-column: 5;
    }
`;
const IconGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 100%;
    position: relative;
    width: 85%;
    margin: 0 auto;
    padding: 0rem 0 5rem 0;
    @media (min-width: 480px) {
        grid-template-columns: repeat(6, 1fr);
    }
`;
const Title = styled.h1`
    width: 85%;
    margin: 0 auto;
    text-align: left;
    color: #3a4f41;
    padding: 4rem 0;
    font-weight: 100;
`;

const Icon = styled.img`
    justify-self: center;
    align-self: center;
    width: 75%;
    @media (min-width: 480px) {
        width: 25%;
    }
`;
const RefLink = styled.a`
    justify-self: center;
    align-self: center;
    width: 75%;
`;
class Contact extends React.Component<{}> {
    render() {
        return (
            <Wrapper id="contact">
                <motion.div
                    initial={{ opacity: 0, y: 100 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.25, delay: 0.35 }}
                    viewport={{ once: true }}
                >
                    <Title>Questions? Get in Touch with me!</Title>
                    <IconGrid>
                        <RefLink
                            target="_blank"
                            href="https://linkedin.com/in/noahwalz/"
                        >
                            <Icon src={linkedIn}></Icon>
                        </RefLink>
                        <RefLink
                            target="_blank"
                            href="mailto:noah@noahwalz.com"
                        >
                            <Icon src={mail}></Icon>
                        </RefLink>
                        <RefLink
                            target="_blank"
                            href="https://instagram.com/n2bln"
                        >
                            <Icon src={instagram}></Icon>
                        </RefLink>

                        <RefLink
                            target="_blank"
                            href="mailto:noah@noahwalz.com"
                        >
                            <ContactButton>Contact Me</ContactButton>
                        </RefLink>
                    </IconGrid>
                </motion.div>
            </Wrapper>
        );
    }
}

export default Contact;
