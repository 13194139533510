import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import { motion, useAnimation, useInView } from "framer-motion";
import n2 from "../../assets/n2apparel-shop.png";
import n2mobile from "../../assets/n2apparel-mobile.JPG";
import n2Tablet from "../../assets/n2apparel-ipad.png";
const RefWrapper = styled.div<{ order: string }>`
    width: 95%;
    text-align: center;
    grid-row: 1;
    background: ${({ order }) => (order == "2" ? "#e6e6e6 " : "#fff")};
    margin: 0rem 0 2rem auto;
    row-gap: 1rem;
    @media (min-width: 480px) {
        width: 85%;
        height: max-content;
        margin: ${({ order }) => (order == "2" ? "0 0 0 auto " : "0 auto 0 0")};
        grid-column: ${({ order }) => (order == "2" ? "1" : "2")};
    }
`;
const References = styled.div<{ projects: string }>`
    background-color: ${({ projects }) =>
        projects == "first" ? "#fff" : "#e6e6e6"};
    position: relative;
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: max-content max-content;
    padding: 0;
    grid-row-gap: 1rem;
    @media (min-width: 480px) {
        grid-template-columns: ${({ projects }) =>
            projects == "first" ? "70% 30%" : "30% 70%"};
        grid-template-rows: 100%;
        padding: 2rem 0;
    }
    > div > h2 {
        grid-column: ${({ projects }) => (projects == "first" ? "2" : "1")};
    }
`;
export const Wrapper = styled.div<{ occasion: string }>`
    display: block;
    width: 100%;
    background: ${({ occasion }) =>
        occasion == "first-project" ? "#e6e6e6" : "#fff"};
    position: -webkit-sticky;
    position: sticky;
    z-index: 99;
    margin-top: 0;

    ${References}:nth-child(4) > div:nth-child(2) {
        grid-row: 1;
        grid-column: 1;
        @media (min-width: 480px) {
            grid-column: 2;
            grid-row: 0;
        }
    }
`;
const Header = styled.h3`
    text-align: left;
    width: 90%;
    margin: 0 auto;
    padding: 1rem 0;
    position: relative;
    color: #69393c;
    text-transform: uppercase;
    @media (min-width: 480px) {
        padding: 2rem 0;
    }
`;

const ProjectImage = styled.img`
    width: 90%;
    display: block;
    position: relative;
    margin: 0 auto;
    content: url(${n2mobile});
    @media (min-width: 640px) {
        content: url(${n2Tablet});
    }
    @media (min-width: 970px) {
        content: url(${n2});
    }
`;

const Iframe = styled.iframe`
    width: 95%;
    height: 95%;

    margin: 0 auto;
    display: grid;
    min-height: 80vh;
    height: 90vh;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);

    border: 0;
    @media (min-width: 480px) {
        width: 85%;
        grid-row: 2 / 4;
        min-height: 60vh;
        height: 60vh;
    }
    @media (min-width: 1024px) {
        min-height: 80vh;
        height: 80vh;
    }
`;
const MainHeader = styled.h2<{ order: number }>`
    display: grid;
    position: relative;
    top: 10%;
    text-align: left;
    color: #3a4f41;
    margin: 1rem 0 0 0;
    width: 100%;
    @media (min-width: 480px) {
        margin: 0;
    }
`;
const SubText = styled.p<{ order: number }>`
    display: grid;
    position: relative;
    top: 20%;
    color: #69393c;
    text-align: left;
    margin: ${({ order }) =>
        order == 1 ? "0rem 0 0 auto" : "0rem 0 2rem auto"};

    line-height: 1.5rem;
    width: 85%;
    float: left;
    padding-bottom: 2rem;
    @media (min-width: 480px) {
        margin: ${({ order }) =>
            order == 1 ? "2rem 0 0 auto" : "2rem 0 2rem auto"};
    }
`;

export const Projects = ({}) => {
    const ref = useRef(null);
    const isInview = useInView(ref, { once: true });
    {
        return (
            <>
                <Wrapper occasion={"first"} ref={ref} id="portfolio">
                    <Header>Portfolio</Header>

                    <References projects={"first"}>
                        <motion.div
                            initial={{ opacity: 0, y: 75 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.45, delay: 0.45 }}
                            viewport={{ once: true }}
                        >
                            <Wrapper occasion={""}>
                                <Iframe src="https://boobtech.noahwalz.com/"></Iframe>
                            </Wrapper>
                        </motion.div>

                        <RefWrapper order={"1"}>
                            <motion.div
                                initial={{ opacity: 0, y: 75 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.45, delay: 0.45 }}
                                viewport={{ once: true }}
                            >
                                <MainHeader order={1}>
                                    Brajuu Web Applikation
                                </MainHeader>
                            </motion.div>
                            <motion.div
                                initial={{ opacity: 0, y: 75 }}
                                whileInView={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.45, delay: 0.45 }}
                                viewport={{ once: true }}
                            >
                                <SubText order={1}>
                                    The FitQuiz is a React-based web application
                                    that recommends the perfect bra for each
                                    woman. Built using React TypeScript, styled
                                    components, and a grid system for high
                                    responsiveness. <br />[ Unfortunately, the
                                    application won't show any results anymore,
                                    because the backend part got taken down 😢.
                                    But, you can still click through the
                                    frontend part! ]
                                </SubText>
                            </motion.div>
                        </RefWrapper>
                    </References>

                    <References projects={""}>
                        <motion.div
                            initial={{ opacity: 0, y: 75 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.45, delay: 0.55 }}
                            viewport={{ once: true }}
                        >
                            <RefWrapper order={"2"}>
                                <MainHeader order={2}>
                                    N2 APPAREL Online Shop
                                </MainHeader>
                                <SubText order={2}>
                                    N2 APPAREL is Berlin based fashion label.
                                    Their Online Shop was build using Shopify's
                                    liquid and custom design and features build
                                    with scss. Build for high performance and a
                                    great user experience for every customer.
                                </SubText>
                            </RefWrapper>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 75 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.45, delay: 0.55 }}
                            viewport={{ once: true }}
                        >
                            <Wrapper occasion={"first-project"}>
                                <ProjectImage src={n2}></ProjectImage>
                            </Wrapper>
                        </motion.div>
                    </References>

                    <References projects={"first"}>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.45, delay: 0.55 }}
                            viewport={{ once: true }}
                        >
                            <Wrapper occasion={"alt-project"}>
                                <Iframe src="https://endzone-clan.de"></Iframe>
                            </Wrapper>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 100 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            viewport={{ once: true }}
                            transition={{ duration: 0.45, delay: 0.55 }}
                        >
                            <RefWrapper order={"3"}>
                                <MainHeader order={1}>
                                    Clash of Clans – Official "Endzone" Clan
                                    website
                                </MainHeader>
                                <SubText order={3}>
                                    Clash of Clans is a popular mobile strategy
                                    game in which players are able to build a
                                    Clan together with other players. "Endzone"
                                    is a Clan of currently 300+ members and 6
                                    Clans in total. Altought the website is not
                                    dynamic in any way, it was build using React
                                    Typescript with styled components in order
                                    to reuse as much as possible and to keep the
                                    code clean and maintainable.
                                </SubText>
                            </RefWrapper>
                        </motion.div>
                    </References>
                </Wrapper>
            </>
        );
    }
};
export default Projects;
