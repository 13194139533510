import React, { lazy } from "react";
import "./App.css";
import NavBar from "./components/head/navbar";
import "react-loading-skeleton/dist/skeleton.css";
import GlobalStyles from "./globalStyles";
import Projects from "./components/body/projects";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Root from "../src/routes/root";
import ErrorPage from "./error-page";
import Skill from "./components/body/skill";
import Experience from "./components/body/experience";
import Contact from "./components/body/contact";
import Footer from "./components/body/footer";
const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/brajuu",
                element: <Root />
            }
        ]
    }
]);
const Home = lazy(() => import("./components/body/home"));
function App() {
    return (
        <div className="App">
            <GlobalStyles />
            <NavBar height />
            <Home />
            <Projects />
            <Skill />
            <Experience />
            <Contact />
            <Footer />
            <RouterProvider router={router} />
        </div>
    );
}

export default App;
